import React from 'react';
import { useMediaQuery } from 'react-responsive';

import TopNav from 'components/TopNav';
import Title from 'components/sections/Title';
import Listables from 'components/sections/Listables';
import Showcase from 'components/sections/Showcase';
import Screenshots2 from 'components/sections/Screenshots2';
import Summary from 'components/sections/Summary';
import ChooseInnovation from 'components/sections/ChooseInnovation';
import Contact from 'components/sections/Contact';
import Footer from 'components/Footer';

import TitleBg from 'images/title_bg5.jpg';
import ShowcaseDevice from 'images/inergy/battery.png';
import ShowcaseBackground from 'images/texture/orange/mist.jpg';
import fade from 'images/inergy/case-study-main.png';
import page1 from 'images/inergy/case-study-sub1.png';
import page2 from 'images/inergy/case-study-sub2.png';
import page3 from 'images/inergy/case-study-sub3.png';
import quoteBackground from 'images/texture/orange/slant.png';
import { SHOWCASE_COPY } from 'copy';

function Explore() {
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
  });

  return (
    <>
      <TopNav />
      <Title
        background={TitleBg}
        topic={'INNOVATION OFFICERS'}
        title={'Pioneer New Industry Standards'}
        subheader={
          'Push the boundaries of your industry by ushering new innovation using Data Science, Artificial Intelligence, and IoT.'
        }
      />
      <Listables
        title={'Start a Relationship with Your Future Target Market, Now'}
        copy={SHOWCASE_COPY.listables.explore}
        gradient={{
          color1: '#ee5ca4',
          color2: '#f49e38'
        }}
      />
      <Showcase
        title={`From Home Batteries to Artificial Intelligence All-in-one Power Concierge`}
        subheader={`EyeCue transforms home energy storage into home energy advisor.
        `}
        background={ShowcaseBackground}
        deviceImg={ShowcaseDevice}
        deviceStyles={
          !isDesktop
            ? { top: '-400px', width: '400px', left: '0px' }
            : undefined
        }
        copy={SHOWCASE_COPY.showcase.explore}
      />
      <Screenshots2
        body={`EyeCue Lab is a true technical partner that helped us translate our business vision into the technical solution that helped us secure customers, partners and investors to realize our goals.`}
        author={'Sean Luangrath'}
        company={'CEO, Inergy'}
        quoteBackground={quoteBackground}
        quoteColor={`orange`}
        screenshots={{
          fade,
          page1,
          page2,
          page3
        }}
      />
      <Summary />
      <ChooseInnovation />
      <Contact />
      <Footer />
    </>
  );
}

export default Explore;
