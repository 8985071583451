import { useEffect, useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { animated, Transition } from 'react-spring';
import classNames from 'classnames';
import podcastModalIsOpenState from './isOpenState';
import styles from './style.module.scss';

const PodcastModal = () => {
  const [podcastModalIsOpen, setPodcastModalIsOpen] = useRecoilState(
    podcastModalIsOpenState
  );

  const closeModal = useCallback(
    () => setPodcastModalIsOpen(false),
    [setPodcastModalIsOpen]
  );

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeModal]);

  return (
    <span
      className={classNames(styles.bg, !podcastModalIsOpen && styles.disabled)}
      onClick={closeModal}
    >
      <Transition
        items={podcastModalIsOpen}
        from={{ opacity: 0, transform: 'scale(0.9)' }}
        leave={{ opacity: 0, transform: 'scale(0.9)' }}
        enter={{ opacity: 1, transform: 'scale(1)' }}
        delay={100}
      >
        {(styles, item) =>
          item && (
            <animated.div onClick={(e) => e.stopPropagation()}>
              <iframe
                title="podcast"
                allow="autoplay *; encrypted-media *; fullscreen *"
                frameBorder="0"
                width="600"
                height="450"
                sandbox="allow-forms allow-popups allow-same-origin allow-scripts  allow-top-navigation-by-user-activation"
                // allow-storage-access-by-user-activation
                src="https://embed.podcasts.apple.com/us/podcast/headspring/id1576579873"
              ></iframe>
            </animated.div>
          )
        }
      </Transition>
    </span>
  );
};
export default PodcastModal;
export { podcastModalIsOpenState };
