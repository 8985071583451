import React from 'react';
import TopNav from 'components/TopNav';
import Icons from 'components/sections/Icons';
import ImageDetails from 'components/sections/ImageDetails';
import Contact from 'components/sections/Contact';
import Footer from 'components/Footer';

import screenshot1 from 'images/solutions-workflow-bg1.png';
import screenshot3 from 'images/solutions-workflow-bg3.png';

import icon1 from 'images/icon/approval.svg';
import icon2 from 'images/icon/satisfaction.svg';
import icon3 from 'images/icon/visibility.svg';
import icon4 from 'images/icon/error_prevention.svg';
import icon5 from 'images/icon/compliance.svg';
import icon6 from 'images/icon/improvement.svg';

function SolutionsMl() {
  return (
    <>
      <TopNav />
      <Icons
        topic={'Workflow Design & Automation'}
        header={'Human Centric Optimization'}
        subheader={`EyeCue redesigns tools to improve accuracy and job satisfaction by automating tedious processes based on pre-defined systems or business rules`}
        icons={[
          {
            img: icon1,
            title: 'Better Approval Cycles',
            copy: 'Use decision tree based notification to monitor job status and escalate necessary actions'
          },
          {
            img: icon2,
            title: 'Promote Satisfaction',
            copy: 'Remove tedious manual tasks and frees human operator for deeper decisions through automation'
          },
          {
            img: icon3,
            title: 'Improve Visibility',
            copy: 'Identify bottlenecks or key contributors to meet your operational or strategic goals'
          },
          {
            img: icon4,
            title: 'Error Prevention',
            copy: 'Provide easy-to-miss intelligence to human operators to reduce possible errors'
          },
          {
            img: icon5,
            title: 'Compliance Adherence',
            copy: 'Meet security and compliance needs by automating audit requests, internal controls, etc.'
          },
          {
            img: icon6,
            title: 'Continual Improvement',
            copy: 'Track effectiveness and weakness of internal workflow to optimize for business goals'
          }
        ]}
      />
      <ImageDetails
        image={screenshot1}
        title={'Eliminate manual scheduling complexity'}
        copy={
          'The EyeCue team translates the highly intensive human scheduling and logging tasks into an automated workflow to modernize one of the oldest gig-economies, construction flagging. Like Uber and DoorDash, workers may now answer to a job automatically determined by hours, certifications, and seniority.'
        }
      />
      <ImageDetails
        image={screenshot3}
        title={'Optimize sales without scaling the manual labor'}
        copy={
          'In a niche marketplace where sellers act as a network to fulfill the diverse needs of buyers, the EyeCue team introduces a portal that distills the noise of manual tracking into a stage-based fulfillment workflow platform. Not only does it consolidate complex multi-tiered communication, but it also empowers sellers to do what they couldn’t before - provide better services to their customers without needing to scale up more human operators.'
        }
      />
      {/* <Logos withPadding /> */}
      <Contact />
      <Footer />
    </>
  );
}

export default SolutionsMl;
