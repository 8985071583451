import { contactModalIsOpenState } from 'components/ContactModal';
import React from 'react';
import { useSetRecoilState } from 'recoil';
import styles from './style.module.scss';

function Contact() {
  const setContactModalIsOpen = useSetRecoilState(contactModalIsOpenState);
  const openContactModal = () => {
    setContactModalIsOpen(true);
  };
  return (
    <div className={styles.container}>
      <div className={styles.layout}>
        <div className={styles.header}>Let's Build Something Amazing</div>
        <button
          type="button"
          onClick={openContactModal}
          className={styles.button}
        >
          Contact EyeCue
        </button>
      </div>
    </div>
  );
}

export default Contact;
