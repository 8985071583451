import React from 'react';
import { useMediaQuery } from 'react-responsive';

import TopNav from 'components/TopNav';
import Title from 'components/sections/Title';
import Listables from 'components/sections/Listables';
import Showcase from 'components/sections/Showcase';
import Summary from 'components/sections/Summary';
import ChooseInnovation from 'components/sections/ChooseInnovation';
import Contact from 'components/sections/Contact';
import Footer from 'components/Footer';

import TitleBg from 'images/title_bg2.jpg';
import ShowcaseDevice from '../../images/shl/intrapreneur-laptop.png';
import ShowcaseBackground from 'images/texture/teal/mist.jpg';
import QuoteBackground from 'images/texture/teal/slant.png';
import { SHOWCASE_COPY } from 'copy';
import Screenshots2 from 'components/sections/Screenshots2';

function Intrapreneur() {
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
  });
  return (
    <>
      <TopNav />
      <Title
        background={TitleBg}
        topic={'Enterprise Intrapreneurs'}
        title={'Take Your Productivity to New Heights'}
        subheader={
          'Unify all data sources, automate internal workflow, eliminate human error, and maximize your realtime output.'
        }
      />
      <Listables
        title="Modernize your software for the sake of happier internal users"
        copy={SHOWCASE_COPY.listables.intrapreneur}
        gradient={{
          color1: '#55e0a0',
          color2: '#2db8bd'
        }}
      />
      <Showcase
        title={`Reduce Complexity and Eliminate Human Error`}
        subheader={`EyeCue consolidates internal legacy data sources and built an automated workflow system that truly fits the needs.`}
        background={ShowcaseBackground}
        deviceImg={ShowcaseDevice}
        deviceStyles={
          isDesktop
            ? {
                top: '-180px',
                width: '1000px',
                left: '550px'
              }
            : { top: '-320px', width: '450px', left: '0px' }
        }
        copy={SHOWCASE_COPY.showcase.intrapreneur}
      />
      <Screenshots2
        body={`You guys are the real deal and we really appreciate the extra work and long hours.
        I can't wait to calculate the token savings comparisons between the legacy system and Hub. Going to be mind blowing.`}
        author={undefined}
        company={'FlowJo, Director of Sales'}
        quoteBackground={QuoteBackground}
        quoteColor={'green'}
      />
      <Summary />
      <ChooseInnovation />
      <Contact />
      <Footer />
    </>
  );
}

export default Intrapreneur;
