import React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';

import logoEdf from 'images/logo-edf.png';
import logoGoogle from 'images/logo-google.png';
import logoIntel from 'images/logo-intel.png';
import logoKnox from 'images/knox/logo.png';
import logoPowur from 'images/logo-powur.png';
import logoNike from 'images/logo-nike.png';
import logoShl from 'images/shl/logo.png';
import logoInergy from 'images/inergy/logo.png';

function Logos({ withPadding }) {
  return (
    <div
      className={classnames(styles.container, {
        [styles.withPadding]: withPadding
      })}
    >
      <div className={styles.layout}>
        <div className={styles.header}>Innovation is in our DNA</div>
        <div className={styles.subheader}>
          Whether a startup launch or enterprise skunk work, EyeCue is ready to
          shoulder the responsibility of success with you.
        </div>
        <div className={styles.logos}>
          <img src={logoGoogle} alt={'logo-google'} />
          <img src={logoNike} alt={'logo-nike'} />

          <img src={logoEdf} alt={'logo-edf'} />
          <img src={logoPowur} alt={'logo-powur'} />

          <img src={logoIntel} alt={'logo-intel'} />
          <img src={logoKnox} alt={'logo-knox'} />

          <img src={logoShl} alt={'logo-shl'} />
          <img src={logoInergy} alt={'logo-inergy'} />
        </div>
      </div>
    </div>
  );
}

export default Logos;
