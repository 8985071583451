export const SHOWCASE_COPY = {
  showcase: {
    startup: {
      block1: `Powur is the first entrepreneurial platform for solar energy. It empowers individuals to create a business in solar in the palm of their hand. The vision is to put solar panels on 2,000,000 homes by 2025.
      <br />
      <br />
      Powur's journey from ideation to launch was fraught with challenges such as evolving business models, adapting to new customer experiences, pivoting user roles to capture additional market share, and maintaining a multi-tiered marketplace that speaks to different audiences`,
      block2: `To date, the EyeCue team has embraced each scenario and stood shoulder-to-shoulder with Powur to take on each challenge with confidence.
      <br />
      <br />
      Under this partnership model, the EyeCue team acts as Powur's internal technology resource and functions as an active product design department. In addition to providing system architecture and deployment strategy, the EyeCue team also crafts branding and marketing material that makes the end-to-end user experience cohesive.`,
      block3: `Over the last eight years, the EyeCue team has launched multiple web and mobile initiatives, acted as CTO and CPO in front of the investors during fundraising, and even provided direct support to their growing customer base.
      <br />
      <br />
      Today, Powur is valued at over 400 million dollars. And it continues to be a consortium of solar installers, manufacturers, financiers, and entrepreneurs who seek to bring the best-of-breed products and services to the end consumer.`
    },
    intrapreneur: {
      block1: `Over the last 20 years, SHL has been in the forefront of combining artificial intelligence and psychometrics to help large enterprises pinpoint the best talents at scale.
      <br />
      <br />
      SHL’s Talent Assessment approach relies heavily on psychometrics; a field of psychology study that develops frameworks and theories related to testing, measurement, and assessment of capabilities.`,
      block2: `As part of the digital transformation initiative, SHL needs a new approach that addresses their business challenges, while simultaneously simplifying the human-capital-intensive sales and delivery process across the customer journey.
      <br />
      <br />
      As part of the digital transformation initiative, SHL needs a new approach that addresses their business challenges, while simultaneously simplifying the human-capital-intensive sales and delivery process across the customer journey.`,
      block3: `This optimization serves as an opportunity to both replace some legacy features, and also build out additional innovations such workflow automation and AI recommendations.  The long-term goal is to provide a pathway for  enterprises to engage SHL without friction.  
      <br />
      <br />
      By working directly with SHL’s science and product leaderships, the EyeCue Lab team is able to help simplify existing process, minimize handoffs across departments, and create efficiency to support scale for future sales.`
    },
    product: {
      block1: `Many of Knox’s biggest challenges are the limitations of the current implementation of its technology stack. Like many companies, Knox has outgrown existing solutions that were acceptable at the time they were completed.`,
      block2: `This is not an uncommon issue; many companies struggle with legacy systems that are unable to scale for growth as the organization matures. Additionally, competitive marketplaces drive the need for innovation as an organization moves to respond to new and changing business needs of its customers.`,
      block3: `Knox consulted the EyeCue Team to improve quality-of-life of users and address many of the most common and entrenched pain points.
      <br />
      <br />
      With the new solution, the platform can better accommodate multi-organizational management, bulk user management, robust access controls and policy administration, and becomes a conduit of communications between the system and stakeholders.`
    },
    explore: {
      block1: `In a recent report by Bloomberg New Energy Finance, the global energy storage market is forecasted to “double six times” from now to 2030. In the US alone, the energy storage market is estimated to experience a twelvefold growth between 2016 and 2022, reaching 7.2 gigawatt-hours of capacity.
      <br />
      <br />
      In response to the growing demand, Inergy and EyeCue Lab formed a strategic partnership in 2018 with the goal to bring Homebase, a highly scalable and modular home energy storage solution to the market.`,

      block2: `More than just a battery, the vision for Homebase is to become an integral part of the home automation solution. Not only is Homebase Native OS going to power the UI that allows users to see its various states and metrics, it will be the brain behind different Nanogrid configurations.
      <br />
      <br />
      EyeCue also develops Homebase Cloud as the central data repository of all Inergy batteries and a single-source-of-truth for customer and reseller information.`,

      block3: `Finally, EyeCue designs Homebase mobile app to complete the digital ecosystem. More than the extension part of Homebase Native OS, the powerful CPUs on the modern mobile devices play a central role to provide advanced functionalities including machine learning logic and recommendation for optimal energy usage patterns.`
    }
  },
  listables: {
    explore: {
      copy: `For a company to last for generations, it must stay relevant and ahead of the curve.  With Eyecue’s support, you have the opportunity to introduce rapid discovery and innovation to your organization.
      <br />
      <br />
      Steeped in the startup culture, the EyeCue team can easily research and develop what’s next. Combined with our experience collaborating with internal stakeholders and pushing decisions through to the finish line, EyeCue is the disruptor you need at the forefront of your industry.`
    },
    intrapreneur: {
      copy: `Software modernization is a neverending journey. But innovation isn’t just about building something new. It’s also about making what you have work better.
      <br />
      <br />
      EyeCue’s deep history operationalizing digital automation to solve enterprise challenges can provide immediate and demonstrable efficiency gain no matter if you want to rebuild, refactor, of reconsolidate your internal software ecosystems.`
    },
    startup: {
      copy: `Seasoned serial entrepreneurs always recruit the same people time
      and time again to recreate that same magic.
      <br />
      <br />
      Over the last 8 years, EyeCue’s entrepreneur-first culture has fostered the perfect environment to incubate innovation teams. We embody this very unique startup spark and have consistently launched some of the most successful startups.
      <br />
      <br />
      The key ingredient to unlocking the explosive excitement and
      progress in early startup phases lies in this perfect alignment of
      experience, personality, and capabilities among the core members
      of your team.`
    },
    product: {
      copy: `EyeCue’s experience in servicing a broad spectrum of industries gives us a unique advantage on assessing market fit for new products.
      <br />
      <br />
      Whenever you are ready to expand your products and services into a new vertical, EyeCue is prepared to leverage digital innovation to both reignite the excitement of your existing customer base and capture new market segments.`
    }
  }
};
