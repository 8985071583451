import { useEffect, useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { animated, Transition } from 'react-spring';
import classNames from 'classnames';
import isOpenState from './isOpenState';

import styles from './style.module.scss';

const ContactModal = () => {
  const [isOpen, setIsOpen] = useRecoilState(isOpenState);

  const { pathname } = useLocation();

  const closeModal = useCallback(() => setIsOpen(false), [setIsOpen]);

  const [description, setDescription] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  // Close modal on esc key
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        closeModal();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [closeModal]);

  // const onSubmit = useCallback(
  //   (e) => {
  //     e.preventDefault();
  //     const form = e.target;
  //     console.log(form);
  //     const params = new URLSearchParams();

  //     params.append('oid', '00D5Y000001cN6Q');
  //     params.append('retURL', '');
  //     params.append('description', description);
  //     params.append('company', companyName);
  //     params.append('first_name', firstName);
  //     params.append('last_name', lastName);
  //     params.append('email', email);
  //     params.append('phone', phone);
  //     console.log(params.toString());
  //     const xhr = new XMLHttpRequest();
  //     xhr.open(form.method, form.action);
  //     xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
  //     xhr.onreadystatechange = () => {
  //       if (xhr.readyState !== XMLHttpRequest.DONE) return;
  //       if (xhr.status === 200) {
  //         setDescription('');
  //         setCompanyName('');
  //         setFirstName('');
  //         setLastName('');
  //         setEmail('');
  //         setPhone('');
  //         closeModal();
  //       } else {
  //         alert('Error');
  //       }
  //     };
  //     xhr.send(params.toString());
  //   },
  //   [description, companyName, firstName, lastName, email, phone, closeModal]
  // );

  return (
    <span
      className={classNames(styles.bg, !isOpen && styles.disabled)}
      onClick={closeModal}
    >
      <Transition
        items={isOpen}
        from={{ opacity: 0, transform: 'scale(0.9)' }}
        leave={{ opacity: 0, transform: 'scale(0.9)' }}
        enter={{ opacity: 1, transform: 'scale(1)' }}
        delay={100}
      >
        {(styles, item) =>
          item && (
            <animated.form
              style={styles}
              onClick={(e) => e.stopPropagation()}
              action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
              method="POST"
              // onSubmit={onSubmit}
            >
              <input type="hidden" name="oid" value="00D5Y000001cN6Q" />
              <input
                type="hidden"
                name="retURL"
                value={`https://www.eyecuelab.com${pathname}`}
              />

              <button type="button" className="x" onClick={closeModal}>
                &times;
              </button>
              <svg>
                <polygon points="0,0 400,0 0,700" fill="rgba(0,0,0,0.03)" />
              </svg>
              <header className="box">
                <h1>Contact Us</h1>
                <p>
                  For all new business inquiries, please contact us through the
                  "Contact Us" form or at{' '}
                  <a href="mailto:newbiz@eyecuelab.com">newbiz@eyecuelab.com</a>
                  .
                </p>
              </header>
              <main>
                <section>
                  <div>
                    <label htmlFor="how-can-we-help">How Can We Help?</label>
                    <textarea
                      type="rich-text"
                      id="how-can-we-help"
                      name="description"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      required
                    />
                  </div>
                </section>
                <section>
                  {/* <div>
                    <label htmlFor="recipient">Recipient</label>
                    <select id="recipient">
                      <option value="1">New Business</option>
                    </select>
                  </div> */}

                  <div>
                    <label htmlFor="company_name">Company Name</label>
                    <input
                      type="text"
                      id="company_name"
                      name="company"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="first_name">First Name</label>
                    <input
                      type="text"
                      id="first_name"
                      name="first_name"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="last_name">Last Name</label>
                    <input
                      type="text"
                      id="last_name"
                      name="last_name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div>
                    <label htmlFor="phone">Phone</label>
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                </section>
              </main>
              <footer>
                <button type="submit">Send Message</button>
              </footer>
            </animated.form>
          )
        }
      </Transition>
    </span>
  );
};
export default ContactModal;
export { isOpenState as contactModalIsOpenState };
