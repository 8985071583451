import React, { useEffect } from 'react';
import TopNav from 'components/TopNav';
import Intro from 'components/sections/Intro';
import Logos from 'components/sections/Logos';
import Cards from 'components/sections/Cards';
import Summary from 'components/sections/Summary';
import ChooseInnovation from 'components/sections/ChooseInnovation';
import Contact from 'components/sections/Contact';
import Footer from 'components/Footer';
import { useLocation } from 'react-router';
import { useSetRecoilState } from 'recoil';
import { podcastModalIsOpenState } from 'components/PodcastModal';
import InnovationQuizBanner from 'components/sections/InnovationQuizBanner';

function Home() {
  const { search } = useLocation();
  const setPodcastModalOpen = useSetRecoilState(podcastModalIsOpenState);

  useEffect(() => {
    if (new URLSearchParams(search).has('podcast')) {
      setPodcastModalOpen(true);
    }
  }, [search, setPodcastModalOpen]);

  return (
    <>
      <TopNav />
      <Intro />
      <Logos />
      <InnovationQuizBanner />
      <Cards />
      <Summary backgroundColor={'f7f9fc'} />
      <ChooseInnovation />
      <Contact />
      <Footer />
    </>
  );
}

export default Home;
