import React from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';

function Slant(props) {
  const { bottom, customStyle } = props;
  const points = '0,0 100,0 100,100, 0,1';

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      fill="white"
      preserveAspectRatio="none"
      style={customStyle}
      className={classnames(styles.slant, {
        [styles.bottom]: bottom
      })}
    >
      <polygon fill="white" points={points} />
    </svg>
  );
}

export default Slant;
