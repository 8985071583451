import React from 'react';
import styles from './style.module.scss';
import powurCardImg from '../../../images/powur/card.png';
import shlCardImg from '../../../images/shl/card.png';
import knoxCardImg from '../../../images/knox/card.png';
import inergyCardImg from '../../../images/inergy/card.png';
import { HashLink as Link } from 'react-router-hash-link';

const CARD_DATA = [
  {
    title: 'Powur | Marketplace, Automation',
    summary: 'Automation that improves your valuation',
    imageUrl: powurCardImg,
    linkTo: '/startups'
  },
  {
    title: 'SHL | Engagement, Design',
    summary: 'Increase engagement and reduce friction',
    imageUrl: shlCardImg,
    linkTo: '/enterprise'
  },
  {
    title: 'Knox | SaaS, Product Discovery',
    summary: 'Software that grows with your business',
    imageUrl: knoxCardImg,
    linkTo: '/product'
  },
  {
    title: 'Inergy | Machine Learning',
    summary: 'Forge a daring path into your industry’s future',
    imageUrl: inergyCardImg,
    linkTo: '/explore'
  }
];

function Card({ title, summary, imageUrl, imageAlt, linkTo }) {
  return (
    <div className={styles.card}>
      <img src={imageUrl} alt={imageAlt} />
      <div className={styles.group}>
        <div className={styles.company}>{title}</div>
        <div className={styles.subject}>{summary}</div>
        <Link smooth to={`${linkTo}#learn-more`}>
          <div className={styles.button}>Learn More</div>
        </Link>
      </div>
    </div>
  );
}

function Cards() {
  return (
    <div className={styles.container}>
      <div className={styles.background} />
      <div className={styles.layout}>
        <div className={styles.topic}>case studies</div>
        <div className={styles.header}>
          Connect the Dots Between Usability & Technology
        </div>
        <div className={styles.subheader}>
          See how we leverage AI, digital product development, and user-first
          design to transform how these industries work.
        </div>
        <div className={styles.scrollWrap}>
          <div className={styles.cards}>
            {CARD_DATA.map((props, index) => (
              <Card key={props.title} {...props} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cards;
