import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import styles from './style.module.scss';

function ChooseInnovation() {
  const { pathname } = useLocation();

  return (
    <div
      className={classNames(styles.container, {
        [styles.allLinks]: pathname === '/'
      })}
    >
      <div className={styles.layout}>
        <div className={styles.header}>Choose Your Adventure</div>
        <div className={styles.subheader}>Which type of innovator are you?</div>
        <div className={styles.row}>
          {pathname !== '/startups' && (
            <div className={styles.block}>
              <div className={styles.title}>Angel-Backed Startups</div>
              <div className={styles.body}>
                Congratulations, you’ve found early investors who share your
                vision!
                <br />
                <br />
                Now you need a seasoned team with a passion for launching
                startups. Let us take your vision to the highest quality MVP.
              </div>
              <Link
                className={classNames(styles.button, {
                  [styles.active]: pathname === `/startups`
                })}
                to={`/startups`}
              >
                learn more
              </Link>
            </div>
          )}
          {pathname !== '/enterprise' && (
            <div className={styles.block}>
              <div className={styles.title}>Enterprise Intrapreneurs</div>
              <div className={styles.body}>
                You know your team will be so much more productive if you can
                automate manual processes and update some of the legacy systems.
                <br />
                <br />
                Now you need a team to build the right tools without costing an
                arm and a leg.
              </div>
              <Link
                className={classNames(styles.button, {
                  [styles.active]: pathname === `/enterprise`
                })}
                to={`/enterprise`}
              >
                learn more
              </Link>
            </div>
          )}
          {pathname !== '/product' && (
            <div className={styles.block}>
              <div className={styles.title}>Product Owners</div>
              <div className={styles.body}>
                Your company’s growth has slowed. It's time to identify
                opportunities to pivot your product or service offerings.
                <br />
                <br />
                Now you need a team with deep industry perspective that can help
                illuminate new possibilities.
              </div>
              <Link
                className={classNames(styles.button, {
                  [styles.active]: pathname === `/product`
                })}
                to={`/product`}
              >
                learn more
              </Link>
            </div>
          )}
          {pathname !== '/explore' && (
            <div className={styles.block}>
              <div className={styles.title}>Innovation Officers</div>
              <div className={styles.body}>
                Your company would like to expand market share in new digital
                frontiers beyond its core offerings.
                <br />
                <br />
                Now you need a team that has a deep history in working with
                bleeding edge technology to execute your vision.
              </div>
              <Link
                className={classNames(styles.button, {
                  [styles.active]: pathname === `/explore`
                })}
                to={`/explore`}
              >
                learn more
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChooseInnovation;
