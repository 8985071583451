export const COPY = {
  innovation: {
    header: `Who We Help`,
    subheader: `We help business grow through innovation`,
    links: [
      {
        mobileTitle: `Angel-Backed Startups`,
        title: 'Angel-Backed Startups',
        body: `Design and launch minimum viable product`,
        url: 'startups'
      },
      {
        mobileTitle: `Enterprise Intrapreneurs`,
        title: 'Enterprise Intrapreneurs',
        body: `Modernize internal workflows and architecture`,
        url: 'enterprise'
      },
      {
        mobileTitle: `Product Owners`,
        title: 'Product Owners',
        body: `Increase market share through product innovation`,
        url: 'product'
      },
      {
        mobileTitle: `Innovation Officers`,
        title: 'Innovation Officers',
        body: `Discover ways to revolutionize your industry`,
        url: 'explore'
      }
    ]
  },
  solutions: {
    header: `What We Do`,
    subheader: `We help business grow through innovation`,
    links: [
      {
        mobileTitle: `ML Applications`,
        title: `AI-Assisted & ML Applications`,
        body: `Spark creativity and improve productivity through meaningful insights`,
        url: 'ml-applications'
      },
      {
        mobileTitle: `Workflow Automation`,
        title: `Workflow Design & Automation`,
        body: `Reduce error and increase satisfaction by eliminating repetitive tasks`,
        url: 'workflow-automation'
      },
      {
        mobileTitle: `Strategic Partnership`,
        title: `Enterprise Strategic Partnership`,
        body: `Maximize your product innovations through high impact partnerships`,
        url: 'strategic-partnership'
      }
    ]
  }
};
