import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import classnames from 'classnames';
import { useSpring, animated } from 'react-spring';

import phone1 from 'images/powur/device-1.png';
import phone2 from 'images/powur/device-2.png';
import laptop2 from 'images/laptop_2.png';
import shlDevice from 'images/shl/intrapreneur-laptop.png';
import inergyDevice from 'images/inergy/battery.png';

const CLIENT_PROJECTS = [
  'Powur | Marketplace, Automation',
  'Knox | SaaS, Product Discovery',
  'Inergy | Machine Learning',
  'SHL | Engagement, Design'
];

let firstTime = true;

function Intro() {
  const [activeBar, setActiveBar] = useState(0);
  const [quoteText, setQuoteText] = useState(CLIENT_PROJECTS[0]);
  const defaultSpringProps = {
    transform: `translateY(-5vh)`,
    opacity: 0,
    config: {
      tension: 150,
      friction: 20
    }
  };
  const enterSpringProps = {
    ...defaultSpringProps,
    transform: `translateY(0vh)`,
    opacity: 1,
    delay: 300
  };
  const leaveSpringProps = {
    ...defaultSpringProps,
    transform: `translateY(5vh)`
  };

  const [springProps0, setSpringProps0] = useSpring(() => defaultSpringProps);
  const [springProps1, setSpringProps1] = useSpring(() => defaultSpringProps);
  const [springProps2, setSpringProps2] = useSpring(() => defaultSpringProps);
  const [springProps3, setSpringProps3] = useSpring(() => defaultSpringProps);

  useEffect(() => {
    if (firstTime) {
      setSpringProps0(enterSpringProps);
      firstTime = false;
    }
    const handle = setTimeout(() => {
      activeBar === 3 ? setActiveBar(0) : setActiveBar(activeBar + 1);
      switch (activeBar) {
        case 0:
          setSpringProps0(leaveSpringProps);
          setSpringProps1(enterSpringProps);
          setQuoteText(CLIENT_PROJECTS[1]);
          break;
        case 1:
          setSpringProps1(leaveSpringProps);
          setSpringProps2(enterSpringProps);
          setQuoteText(CLIENT_PROJECTS[2]);
          break;
        case 2:
          setSpringProps2(leaveSpringProps);
          setSpringProps3(enterSpringProps);
          setQuoteText(CLIENT_PROJECTS[3]);
          break;
        case 3:
          const element = document.getElementById('active1');
          element.classList.remove(`${styles.progressBarInner}`);
          void element.offsetWidth;
          element.classList.add(`${styles.progressBarInner}`);
          setActiveBar(0);
          setSpringProps3(leaveSpringProps);
          setSpringProps0(enterSpringProps);
          setQuoteText(CLIENT_PROJECTS[0]);
          break;
        default:
          throw new Error(`Invalid activeBar: ${activeBar}`);
      }
    }, 5000);
    return () => clearTimeout(handle);
  }, [activeBar]); // eslint-disable-line

  return (
    <div className={styles.container}>
      <div className={styles.layout}>
        <div className={styles.intro}>
          <div className={classnames(styles.phones, styles.mobileOnly)}>
            <img className={styles.device} src={phone1} alt="phone1" />
            <img
              className={classnames(styles.device, styles.phone)}
              src={phone2}
              alt="phone2"
            />
          </div>
          <div>
            <div className={styles.header}>envision design innovate</div>
            <div className={styles.subheader}>
              We are an innovation and software engineering firm that helps
              companies innovate, transform, and grow.
            </div>
            <div className={styles.barGroup}>
              <div
                className={classnames(styles.progressBar, {
                  [styles.active]: activeBar > -1
                })}
              >
                <div id="active1" className={styles.progressBarInner}></div>
              </div>
              <div
                className={classnames(styles.progressBar, {
                  [styles.active]: activeBar > 0
                })}
              >
                <div className={styles.progressBarInner}></div>
              </div>
              <div
                className={classnames(styles.progressBar, {
                  [styles.active]: activeBar > 1
                })}
              >
                <div className={styles.progressBarInner}></div>
              </div>
              <div
                className={classnames(styles.progressBar, {
                  [styles.active]: activeBar > 2
                })}
              >
                <div className={styles.progressBarInner}></div>
              </div>
            </div>
            <div className={styles.barTitle}>
              <div>{quoteText}</div>
              {/* <div>Powur | Marketplace, Automation</div>
              <div>Powur | Marketplace, Automation</div>
              <div>Powur | Marketplace, Automation</div> */}
            </div>
          </div>
          <animated.div
            className={styles.phones}
            style={{
              opacity: springProps0.opacity,
              transform: springProps0.transform
            }}
          >
            <img className={styles.device} src={phone1} alt="phone1" />
            <img
              className={classnames(styles.device, styles.phone)}
              src={phone2}
              alt="phone2"
            />
          </animated.div>
          <animated.div
            className={styles.phones}
            style={{
              opacity: springProps1.opacity,
              transform: springProps1.transform
            }}
          >
            <img
              className={classnames(styles.device, styles.laptop)}
              src={laptop2}
              alt="laptop"
            ></img>
          </animated.div>
          <animated.div
            className={styles.phones}
            style={{
              opacity: springProps2.opacity,
              transform: springProps2.transform
            }}
          >
            <img
              className={classnames(styles.device, styles.inergy)}
              src={inergyDevice}
              alt="laptop"
            ></img>
          </animated.div>
          <animated.div
            className={styles.phones}
            style={{
              opacity: springProps3.opacity,
              transform: springProps3.transform
            }}
          >
            <img
              className={classnames(styles.device, styles.shl)}
              src={shlDevice}
              alt="tablet"
            ></img>
          </animated.div>
        </div>
      </div>
    </div>
  );
}

export default Intro;
